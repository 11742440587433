import React from 'react';
import {Layout} from "../components/Layout";
import {BackgroundLine} from "../components/BackgroundLine";
import {BlockTitle} from "../components/BlockTitle";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {Typography} from "../components/Typography";
import * as styles from './privacyPolicy.module.scss';

export const query = graphql`
    query Privacy($locale: String!) {
        page: contentfulPrivacyPolicy(node_locale: {eq: $locale}) {
            title
            content {
                childMarkdownRemark {
                    html
                }
            }

            seo {
                ...Seo
            }
        }
    }
`
export default function PrivacyPolicyPage({data, pageContext}) {
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.page.title}
                   seo={data.page.seo}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <BlockTitle>{data.page.title}</BlockTitle>
            <MarkdownContent component={Typography} className={styles.content} content={data.page.content} />
        </PageMainColumnWrapper>
    </Layout>;
}
